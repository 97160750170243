import React, { Component } from 'react';
import Herosection3 from '../../widgets/herosection/herosection3';
import Counter4 from '../../widgets/counter/counter4';
import BrandLogo from "../../widgets/aboutus/brand";
import Memberaboutus from "../../widgets/aboutus/aboutus";

class Index4 extends Component {
  constructor(props)
  {
      super(props)
  }
  componentDidMount() {
      window.scrollTo(0, 0)
  }
    render() {
        return (
            <div>
            {/*header start*/}
            {/*<Header3 />*/}
             {/*header end*/}
            {/*hero section start*/}
            <Herosection3 />
            {/*hero section end*/} 
            {/*body content start*/}
            <div className="page-content">
              {/*services start*/}
              {/*<section>*/}
              {/*  <div className="container">*/}
              {/*    <div className="row justify-content-center text-center">*/}
              {/*      <div className="col-12 col-md-12 col-lg-8 mb-8 mb-lg-0">*/}
              {/*        <div className="mb-8"> <span className="badge badge-primary-soft p-2 font-w-6">*/}
              {/*            What We Offerd*/}
              {/*          </span>*/}
              {/*          <h2 className="mt-3 font-w-5">Creative Design And Creative Services</h2>*/}
              {/*          <p className="lead mb-0">All types of businesses need access to development resources, so we give you the option to decide how much you need to use.</p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    /!* / .row *!/*/}
              {/*    /!*<FeatureServices />*!/*/}
              {/*   </div>*/}
              {/*</section>*/}
              {/*services end*/}
              {/*about start*/}
              <section>
                <div className="container">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-12 text-center">
                      <h2 className="mt-3 font-w-5">Eficacia y precisión con el menor impacto ambiental</h2>
                    </div>
                    <div className="col-12 col-lg-6 mb-8 mb-lg-0">
                      <img src={require(`../../assets/images/bg/maquina.jpg`)} alt="Image" className="img-fluid" />
                      {/*<img src={require(`../../assets/images/about/4.png`)} alt="Image" className="img-fluid" />*/}
                    </div>
                    <div className="col-12 col-lg-6 col-xl-5">
                      <div>
                        {/*<p className="lead mb-0">We use the latest technologies it voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>*/}
                      </div>
                      <Counter4 />
                    </div>
                  </div>
                </div>
              </section>
              <section className="text-center" id={'Nosotros'}>
                <div className="container">
                  <Memberaboutus />
                </div>
              </section>
              <section className="text-center">
                <div className="container">
                  <div className="row justify-content-center text-center">
                    <div className="col-12 col-md-12 col-lg-8 mb-8 mb-lg-0">
                      <div className="mb-8">
                        <h2 className="mt-3">Nuestros clientes</h2>
                      </div>
                    </div>
                  </div>
                  <BrandLogo />
                </div>
              </section>
              <section className="pb-11" id={'PHD'}>
                <div className="container">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-12 col-lg-5 col-xl-5 mb-6 mb-lg-0">
                      <div className="row align-items-center">
                        <div className="col-6">
                          <img src={require(`../../assets/images/about/phd1.png`)} className="img-fluid rounded shadow-lg" alt="..." />
                        </div>
                        <div className="col-6">
                          <img src={require(`../../assets/images/about/phd2.png`)} className="img-fluid rounded shadow-lg mb-5" alt="..." />
                          <img src={require(`../../assets/images/about/phd3.png`)} className="img-fluid rounded shadow-lg" alt="..." />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-7 col-xl-7">
                      <div>
                        <h2 className="mt-3 font-w-5">¿Qué es la Perforación Horizontal Dirigida?</h2>
                        <p className="lead">
                          El Sistema de Perforación Horizontal Dirigido es una tecnología utilizada para la instalación soterrada de redes, evitando el tradicional sistema destructivo de zanjeo a cielo abierto.
                        </p>
                        <p className="lead">
                          Con este sistema se pueden instalar tuberías de P.E.A.D , PVC, Acero, cables de luz y otros, en tramos de hasta 200  mts. realizando solamente aperturas en ambos extremos.
                        </p>
                        <p className="lead">
                          <ul>
                            <li>
                              Reduce tiempos de obra
                            </li>
                            <li>
                              Reduce el impacto visual
                            </li>
                            <li>
                              Reduce costos de operación
                            </li>
                          </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/*about end*/}
              {/*how it work start*/}
              {/*<section className="custom-py-2 position-relative bg-dark" data-bg-img={require(`../../assets/images/bg/02.png`)}>*/}
              {/*  <div className="container">*/}
              {/*    <div className="row justify-content-center text-center">*/}
              {/*      <div className="col-12 col-md-12 col-lg-8 mb-8 mb-lg-0">*/}
              {/*        <div className="mb-8"> <span className="badge badge-primary-soft p-2 font-w-6">*/}
              {/*            How It Work*/}
              {/*          </span>*/}
              {/*          <h2 className="mt-3 font-w-5 mb-0 text-white">Three Simple Step To Started Working Process</h2>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    /!* / .row *!/*/}
              {/*    <div className="row align-items-center justify-content-between mb-10">*/}
              {/*      <div className="col-12 col-lg-6 mb-6 mb-lg-0">*/}
              {/*        <img src={require(`../../assets/images/svg/04.svg`)} alt="Image" className="img-fluid" />*/}
              {/*      </div>*/}
              {/*      <div className="col-12 col-lg-6 col-xl-5">*/}
              {/*        <div>*/}
              {/*          <h2> <span className="badge badge-primary-soft p-2">*/}
              {/*              01*/}
              {/*            </span>*/}
              {/*          </h2>*/}
              {/*          <h4 className="mt-3 text-light">Choose Project</h4>*/}
              {/*          <p className="lead mb-0">We use the latest technologies it voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <div className="row align-items-center justify-content-between mb-10">*/}
              {/*      <div className="col-12 col-lg-6 order-lg-1 mb-6 mb-lg-0">*/}
              {/*        <img src={require(`../../assets/images/svg/05.svg`)} alt="Image" className="img-fluid" />*/}
              {/*      </div>*/}
              {/*      <div className="col-12 col-lg-6 col-xl-5">*/}
              {/*        <div>*/}
              {/*          <h2> <span className="badge badge-primary-soft p-2">*/}
              {/*              02*/}
              {/*            </span>*/}
              {/*          </h2>*/}
              {/*          <h4 className="mt-3 text-light">Start Creating</h4>*/}
              {/*          <p className="lead mb-0">We use the latest technologies it voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <div className="row align-items-center justify-content-between">*/}
              {/*      <div className="col-12 col-lg-6 mb-6 mb-lg-0">*/}
              {/*        <img src={require(`../../assets/images/svg/06.svg`)} alt="Image" className="img-fluid" />*/}
              {/*      </div>*/}
              {/*      <div className="col-12 col-lg-6 col-xl-5">*/}
              {/*        <div>*/}
              {/*          <h2> <span className="badge badge-primary-soft p-2">*/}
              {/*              03*/}
              {/*            </span>*/}
              {/*          </h2>*/}
              {/*          <h4 className="mt-3 text-light">Get Result</h4>*/}
              {/*          <p className="lead mb-0">We use the latest technologies it voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  <div className="shape-1" style={{height: '200px', overflow: 'hidden'}}>*/}
              {/*    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}>*/}
              {/*      <path d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" style={{stroke: 'none', fill: '#fff'}} />*/}
              {/*    </svg>*/}
              {/*  </div>*/}
              {/*  <div className="shape-1 bottom" style={{height: '200px', overflow: 'hidden'}}>*/}
              {/*    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}>*/}
              {/*      <path d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" style={{stroke: 'none', fill: '#fff'}} />*/}
              {/*    </svg>*/}
              {/*  </div>*/}
              {/*</section>*/}
              {/*how it work end*/}
              {/*team start*/}
              {/*<section className="pt-0">*/}
              {/*  <div className="container">*/}
              {/*    <div className="row justify-content-center text-center">*/}
              {/*      <div className="col-12 col-md-12 col-lg-8 mb-8 mb-lg-0">*/}
              {/*        <div className="mb-8"> <span className="badge badge-primary-soft p-2 font-w-6">*/}
              {/*            Bootsland Team*/}
              {/*          </span>*/}
              {/*          <h2 className="mt-3 font-w-5">Meet Our Team Of Expert</h2>*/}
              {/*          <p className="lead mb-0">All types of businesses need access to development resources, so we give you the option to decide how much you need to use.</p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    /!* / .row *!/*/}
              {/*    <Team1 />*/}
              {/*   </div>*/}
              {/*</section>*/}
              {/*team end*/}
              <section>
                <div className="container">
                  <div className="row text-center">
                    <div className="col-lg-4 col-md-6 mt-5">
                      <div>
                        <svg className="feather feather-mail" xmlns="http://www.w3.org/2000/svg" width={50} height={50} viewBox="0 0 24 24" fill="none" stroke="#1360ef" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" /><polyline points="22,6 12,13 2,6" /></svg>
                        <h4 className="mt-5">Contáctese con nosotros</h4>
                        <a href="mailto:catalina@lusalco.com"> catalina@lusalco.com</a>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-5">
                      <div>
                        <svg className="feather feather-phone-call" xmlns="http://www.w3.org/2000/svg" width={50} height={50} viewBox="0 0 24 24" fill="none" stroke="#1360ef" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round"><path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /></svg>
                        <h4 className="mt-5">🇺🇾 Uruguay</h4>
                        <a href="tel:+59895852660">(+598) 9585 2660</a><br/>
                        <a href="tel:+59893732461">(+598) 9373 2461</a>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-5">
                      <div>
                        <svg className="feather feather-phone-call" xmlns="http://www.w3.org/2000/svg" width={50} height={50} viewBox="0 0 24 24" fill="none" stroke="#1360ef" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round"><path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /></svg>
                        <h4 className="mt-5">🇦🇷 Argentina</h4>
                        <a href="tel:+5491149472369">(+549) 11 4947 2369</a><br/>
                        <a href="tel:+5491141990315">(+549) 11 4199 0315</a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            {/*body content end*/}
          </div>
        );
    }
}

export default Index4;