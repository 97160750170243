/**
 *  Main Menu Json
 */
export default [
   {
      "menu_title": "Inicio",
      "path": "/",
      "icon": "home"
   },
   {
      "menu_title": "Sobre Nosotros",
      "path": "/#Nosotros",
      "icon": "home"
   },
   {
      "menu_title": "PHD",
      "path": "/#PHD",
      "icon": "home"
   },
]
