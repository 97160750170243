import React, { Component } from 'react';

class BrandLogo extends Component {
    render() {
        return (

            <div>
                <div className="row align-items-center">
                    <div className="col">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/client/cliente1.png`)} alt="" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/client/cliente2.png`)} alt="" />
                        </div>
                    </div>
                    <div className="col align-baseline">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/client/cliente3.png`)} alt="" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/client/cliente4.png`)} alt="" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/client/cliente5.png`)} alt="" />
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/client/cliente6.png`)} alt="" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/client/cliente7.png`)} alt="" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/client/cliente8.png`)} alt="" />
                        </div>
                    </div>
                    <div className="col align-content-between">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/client/cliente9.png`)} alt="" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/client/cliente10.png`)} alt="" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/client/cliente11.png`)} alt="" />
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}

export default BrandLogo;