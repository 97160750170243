import React, { Component, Fragment } from 'react';
import { HashRouter, Switch, Route ,withRouter} from 'react-router-dom';
import Footer from './layout/footer/footer';

import './App.css';
import './Vendor.js';
import { connect } from 'react-redux';
import Index4 from './pages/Index/index4';
import Scrolltop from './layout/back-to-top';
import Header3 from "./layout/header/header3";

class App extends React.Component {
  constructor(props) {
    super(props)
  }

  getUrl(pathname) {
    let pathArray = pathname.split('/');
    return `/${pathArray[1]}` === '/coming-soon' ? true : `/${pathArray[1]}` === '/maintenance' ? true : `/${pathArray[1]}` === '/page-not-found' ? true : false;
  }
  setHeader(pathname) {
    let pathArray = pathname.split('/');
    return `/${pathArray[1]}` === '/index2' ? true : `/${pathArray[1]}` === '/index3' ? true : `/${pathArray[1]}` === '/index4' ? true : `/${pathArray[1]}` === '/index5' ? true : `/${pathArray[1]}` === '/index9' ? true  : false;

  }
  render() {
    const { location } = this.props;
    return (
        <Fragment>
          {
            this.getUrl(location.pathname) ?
                <Switch>

                </Switch>
                :
                <div className="page-wrapper">
                  {this.setHeader(location.pathname) ? null : <Header3 />}
                  <Switch>
                    <Route exact path="/" component={Index4} />
                  </Switch>
                  <Footer />
                  <Scrolltop />
                </div>
          }
        </Fragment>
    );
  }
}

const AppMapStateToProps = state => {
  return {
    products: state.data.products
  };
};

const AppMapDispatchToProps = dispatch => {
  return {
    getProducts: () => {
      dispatch(getProducts());
    }
  };
};


export default connect(AppMapStateToProps,AppMapDispatchToProps)(withRouter(App))

