import React, { Component } from 'react';

class Memberaboutus extends Component {
    render() {
        return (
              <div className="row align-items-center justify-content-between">
                <div className="col-12 col-lg-6 mb-6 mb-lg-0 order-lg-1">
                  <div className="row align-items-center">
                    <div className="col-6">
                      <img src={require(`../../assets/images/about/team1.png`)} className="img-fluid rounded shadow-lg" alt="..." />
                    </div>
                    <div className="col-6">
                      <img src={require(`../../assets/images/about/team2.png`)} className="img-fluid rounded shadow-lg mt-10" alt="..." />
                    </div>
                    <div className="col-10 ml-auto mr-auto mt-n11">
                      <img src={require(`../../assets/images/about/team4.png`)} className="img-fluid rounded shadow-lg" alt="..." />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-5">
                  <div>
                    <h2 className="mt-3 font-w-5">Lusalco S.A.</h2>
                    {/*<p className="lead">We use the latest technologies it voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>*/}
                    <p className="mb-0">Somos una organización pionera en el uso de la perforación horizontal dirigida para la instalación de tendido de redes urbanas y suburbanas.
                        Con experiencia en obras desde hace más de 12 años en Uruguay y Argentina.
                        <br/>Nuestro equipo de trabajo está altamente capacitado y contamos con maquinaria de última tecnología.</p>
                      <p>
                          <br/>
                         <b> Nuestros Valores:</b><br/>
                              Calidad<br/>
                              Eficiencia<br/>
                              Responsabilidad
                      </p>
                  </div>
                </div>
              </div>
          
        );
    }
}

export default Memberaboutus;