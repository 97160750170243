import React, { Component } from 'react';
import CountUp from 'react-countup';

class Counter4 extends Component {
    render() {
        return (
          
              <div className="row align-items-center mt-5">
                <div className="col-12">
                    <div className="counter">
                        <div className="counter-desc">
                            <CountUp className="count-number display-4" end={150} duration={20} />
                            <span className="display-4 text-primary">mil</span>
                            <h6 className="text-muted mb-0">metros de Fibra óptica</h6>
                        </div>
                    </div>
                </div>
                  <div className="col-12">
                      <div className="counter">
                        <div className="counter-desc">
                            <CountUp className="count-number display-4" end={250} duration={20} />
                            <span className="display-4 text-primary">mil</span>
                            <h6 className="text-muted mb-0">metros de red de distribución de gas</h6>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                  <div className="counter">
                    <div className="counter-desc"> <CountUp className="count-number display-4" end={20} duration={20} />
                      <span className="display-4 text-primary">mil</span>
                      <h6 className="text-muted mb-0">metros de gasoductos</h6>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="counter">
                    <div className="counter-desc"> <CountUp className="count-number display-4" end={90} duration={20} />
                      <span className="display-4 text-primary">mil</span>
                      <h6 className="text-muted mb-0">metros de ducto de saneamiento y agua</h6>
                    </div>
                  </div>
                </div>
                  <div className="col-12">
                      <div className="counter">
                          <div className="counter-desc"> <CountUp className="count-number display-4" end={10} duration={20} />
                              <span className="display-4 text-primary">mil</span>
                              <h6 className="text-muted mb-0">metros de ducto para electricidad</h6>
                          </div>
                      </div>
                  </div>
              </div>
         
        
        );
    }
}

export default Counter4;